import React, { useContext } from 'react';
import clsx from 'clsx';

import {
  Button,
} from '@material-ui/core';

import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';
import CommuteTwoToneIcon from '@material-ui/icons/CommuteTwoTone';
import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';

import { GlobalState } from 'context/GlobalStateProvider';
import { AppHeaderContainer } from './style';

const Header = (props) => {
  const { pageName } = props;
  const {sidebarToggleMobile, setSidebarToggleMobile} = useContext(GlobalState);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  let title, titleIcon;
  switch (pageName) {
    case 'Accounts':
      title = 'ProPay Accounts';
      titleIcon = <MonetizationOnTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'StripePage':
      title = 'Stripe Accounts';
      titleIcon = <CreditCardIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Flash':
      title = 'Flash Funds';
      titleIcon = <AttachMoneyTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingInformation':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    default:
      title = 'ProPay Accounts';
      titleIcon = <MonetizationOnTwoToneIcon style={{ fontSize: 30 }}/>;
      title = 'Stripe Accounts';
      titleIcon = <CreditCardIcon style={{ fontSize: 30 }}/>;
      break;
  }

  return (
    <AppHeaderContainer className={'app-header'}>
      <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
      <div className="app-header--header-left">
        {titleIcon}
      </div>
      <div className="app-header--header-center">
        <h4 className="app-header--internal-name">{title}</h4>
      </div>
      <div className="app-header--header-right">
        
      </div>
    </AppHeaderContainer>
  );
};

export default Header;
