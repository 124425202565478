import styled from 'styled-components';

export const AppHeaderContainer = styled.div`
  border-top: 1px solid #b4c3ca;
  border-bottom: 1px solid #b4c3ca;
  box-shadow: 0px 1px 10px rgba(0,0,0,.2);
  background-color: #f7f9fa;
  padding: 0;
  display: flex;
  justify-content: normal;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 77px;

  .app-header--header-left {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-right: 1px solid #e5ebed;
    width: 65px;
  }

  .app-header--header-center {
    padding: 17px 20px 15px;
    display: flex;
    align-items: center;

    .app-header--internal-name {
      font-size: 21px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .app-header--header-right {
    right: 0;
    position: fixed;
    display: flex;
    align-items: center;
    height: 77px;
    padding-right: 40px;
  }

  @media (min-width: 1099.98px) {
    .toggle-mobile-sidebar-btn {
      display: none;
    }
  }

  @media (max-width: 1099.98px) {
    .app-header--header-left {
      display: none;
    }

    .app-header--header-center {
      border-left: 1px solid #e5ebed;

      .app-header--internal-name {
        font-size: 18px;
      }
    }

    .app-header--header-right {
      padding-right: 20px;
    }
  }
`;
