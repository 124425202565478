import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';

import { isLoggedIn } from 'services/loginService';
import ManageLayout from 'layouts/manageLayout';
import GlobalStateProvider from 'context/GlobalStateProvider';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import NotificationOverlay from 'components/NotificationOverlay';

const ManageTemplate = props => {
  const { pageName } = props.pageContext;
  let PageContent = null;
  switch (pageName) {
    case 'Accounts':
      PageContent = loadable(() => import('./Accounts/Main'));
      break;
    case 'AccountsSetup':
      PageContent = loadable(() => import('./Accounts/Setup'));
      break;
    case 'AccountsEdit':
      PageContent = loadable(() => import('./Accounts/Edit'));
      break;
    case 'StripePage':
      PageContent = loadable(() => import('./stripes/Main'));
      break;
    case 'StripePageSetup':
      PageContent = loadable(() => import('./stripes/Setup'));
      break;
    case 'StripePageEdit':
      PageContent = loadable(() => import('./stripes/Edit'));
      break;
    case 'Flash':
      PageContent = loadable(() => import('./Flash'));
      break;
    case 'SettingInformation':
      PageContent = loadable(() => import('./Settings/Information'));
      break;
    default:
      PageContent = loadable(() => import('./Accounts/Main'));
      break;
  }

  if (!isLoggedIn() && typeof window !== 'undefined') {
    navigate('/login');
    return null;
  }

  if (!PageContent) {
    return null;
  }

  return (
    <GlobalStateProvider>
      <CircularProgressOverlay>
        <NotificationOverlay>
          <ManageLayout pageName={pageName}>
            <PageContent {...props} />
          </ManageLayout>
        </NotificationOverlay>
      </CircularProgressOverlay>
    </GlobalStateProvider>
  );
};

export default ManageTemplate;
