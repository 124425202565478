import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import styled from 'styled-components';

const NotificationContext = React.createContext({
  showNotification: () => {},
});
export { NotificationContext };

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NotificationOverlay = ({
  children
}) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('')
  const [severity, setSeverity] = useState('info');

  const showNotification = (message, type = 'info') => {
    setText(message);
    setSeverity(type);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        showNotification,
      }}
    >
      <div className="notification-overlay-children--wrapper">
        {children}
      </div>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={10000}
        transitionDuration={1000}
        TransitionComponent={Fade}
        onClose={handleClose}
        classes={{ root: `toastr-${severity}` }}
        message={text}
        style={{ zIndex: 11000 }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}

export default NotificationOverlay;

const NotificationContainer = styled.div`
`;