import React from 'react';
import clsx from 'clsx';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

const Sidebar = props => {
  return (
    <div
      className={clsx('app-sidebar', 'app-sidebar--dark', {
        'app-sidebar--shadow': false,
      })}
    >
      <SidebarHeader />
      <div className="app-sidebar--content">
        <SidebarMenu pageName={props.pageName} />
      </div>
    </div>
  );
};

export default Sidebar;
