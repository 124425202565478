import React, { useState } from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import { Collapse, Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';
import CommuteTwoToneIcon from '@material-ui/icons/CommuteTwoTone';
import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import { Link } from 'gatsby';

import { logout } from 'services/loginService';

const SidebarMenu = props => {
  const { pageName } = props;

  const [openAccountsModules, setOpenAccountsModules] = useState(pageName.includes('Accounts'));
  const toggleAccountsModules = event => {
    setOpenAccountsModules(!openAccountsModules);
    event.preventDefault();
  };

  const [openSettingModules, setOpenSettingModules] = useState(pageName.includes('Setting'));
  const toggleSettingModules = event => {
    setOpenSettingModules(!openSettingModules);
    event.preventDefault();
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        <ul>
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/accounts">
              <span className="sidebar-icon">
                <AccountBalanceTwoToneIcon fontSize="medium" />
              </span>
              Propay accounts
            </Link>
          </li>
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/stripe">
              <span className="sidebar-icon">
                <CreditCardIcon fontSize="medium" />
              </span>
              Stripe accounts
            </Link>
          </li>
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/flash">
              <span className="sidebar-icon">
                <AttachMoneyTwoToneIcon fontSize="medium" />
              </span>
              Flash Funds
            </Link>
          </li>
          <li>
            <a
              href="#/"
              onClick={toggleSettingModules}
              className={clsx({ active: openSettingModules })}
            >
              <span className="sidebar-icon">
                <SettingsTwoToneIcon fontSize="medium" />
              </span>
              <span className="sidebar-item-label">Settings</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={openSettingModules}>
              <ul>
                <li>
                  <Link activeClassName="active" to="/manage/settings/information">
                    Information
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li>
            <a className="nav-link-simple" href="#" onClick={handleLogout}>
              <span className="sidebar-icon">
                <ExitToAppTwoToneIcon fontSize="medium" />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
